.image-display {
	display: flex;
	flex-direction: row;

	&__view-wrapper {
		// flex-basis: 80%;
		flex-basis: 70%;
		display: flex;
		flex-direction: column;
	}

	&__view {
		flex-basis: 90%;
		border: 1px solid black;
	}

	&__controls {
		flex-basis: 10%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		.js-state {
			margin: 0 20px;
		}

		.js-image-id {
			margin-left: 20px;
			flex-basis: 16em;
			width: 16em;
		}

		.js-to-commit {
			margin-right: 20px;
		}
	}

	&__filters {
		// flex-basis: 20%;
		flex-basis: 30%;
		padding-left: 20px;
	}

	&__filter {
		margin-bottom: 40px;
	}

	&__checkbox {


		&--sub {
			margin-left: 2em;
		}
	}
}
