body {
	color: $color-grey;
	font-family: 'FuturaNew-400', sans-serif;
}

h1 {
	// @include rem(font-size, 42px);
	// @include rem(margin-bottom, 30px);
	// line-height: 1.1;
	// font-weight: 500;
	// color: $color-grey;
	// font-family: 'FuturaNew-500', sans-serif;
	// --- old ---

	font-family: 'FuturaNew-400', sans-serif;
	font-size: 48px;
	@include rem(line-height, 59px);
	@include rem(margin-bottom, 44px);
	color: $color-black;

	&.br {
		@include rem(margin-top, 90px);
	}
}

.custom-title {
	@include rem(font-size, 70px);
	@include rem(margin-bottom, 30px);
	line-height: 1.2;
	font-weight: 500;
	color: #000000;
	font-family: 'FuturaNew-500', sans-serif;
}

.lead {
	@include rem(font-size, 20px);
	@include rem(line-height, 24px);
	@include rem(margin-bottom, 50px);
	color: $color-grey;
	font-family: 'FuturaNew-500', sans-serif;

	.body--intro & {
		@media (min-height: 600px) {
			@include rem(margin-bottom, 20px);
		}
	}
}

h3 {
	font-family: 'FuturaNew-500', sans-serif;
	@include rem(font-size, 20px);
	@include rem(line-height, 22px);
	@include rem(margin-bottom, 16px);
}

h2 {
	font-family: 'FuturaNew-500', sans-serif;
	@include rem(font-size, 40px);
	@include rem(line-height, 41px);
	@include rem(margin-bottom, 16px);
}

.note {
	font-family: 'FuturaNew-400', sans-serif;
	@include rem(font-size, 16px);
	@include rem(line-height, 16px);
	@include rem(margin-bottom, 16px);
	font-style: italic;
}

.text-comment--required {
	line-height: 1.2;
	font-size: .8rem;
	color: #ba085c;
}
/** Заначка старых принтовых версий
В данный момент не используется
Стилизация вынесена на отдельную страницу
body--print
 */
@media print {
	.brief .layout-wrapper {
		padding: 0;

		.header,
		.sidebar,
		.js-add-btn {
			display: none;
		}

		.page {
			max-width: 100%;

			&__content {
				padding: 1rem 4rem;
			}
		}
	}

	.range-form {
		page-break-after: always;
	}

	// галерея выхлоп
	.b-gallery-final-block {
		height: 45vh;
		overflow: hidden;

		&__image {
			height: 60%;
			flex: 1 1 60%;

			img {
				height: 100%;
				object-fit: contain;
			}
		}

		&__comment-btn,
		&__comment-del {
			display: none;
		}

	}

	.gallery-final__header:not(.not-empty) {
		display: none;
	}

	.gallery-final__header.not-empty {
		page-break-after: avoid;
		display: block;
	}

	// разметка
	.print-wrapper {
		height: 99vh;
	}

	.col-print-1 {
		flex-basis: 8.33333333%;
		max-width: 8.33333333%;
	}

	.col-print-2 {
		flex-basis: 16.66666667%;
		max-width: 16.66666667%;
	}

	.col-print-3 {
		flex-basis: 25%;
		max-width: 25%;
	}

	.col-print-4 {
		flex-basis: 33.33333333%;
		max-width: 33.33333333%;
	}

	.col-print-5 {
		flex-basis: 41.66666667%;
		max-width: 41.66666667%;
	}

	.col-print-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-print-7 {
		flex-basis: 58.33333333%;
		max-width: 58.33333333%;
	}

	.col-print-8 {
		flex-basis: 66.66666667%;
		max-width: 66.66666667%;
	}

	.col-print-9 {
		flex-basis: 75%;
		max-width: 75%;
	}

	.col-print-10 {
		flex-basis: 83.33333333%;
		max-width: 83.33333333%;
	}

	.col-print-11 {
		flex-basis: 91.66666667%;
		max-width: 91.66666667%;
	}

	.col-print-12 {
		flex-basis: 100%;
		max-width: 100%;
	}

	.col-print-offset-1 {
		margin-left: 8.33333333%;
	}
}
