@import "./vars.scss";
@import "./reset.scss";
@import "flexboxgrid/dist/flexboxgrid.css";
// @import "./custom.scss";
@import "~bootstrap/scss/mixins";

@import "./test.scss";

@import "./typography.scss";
@import "./elements.scss";
@import "../components/button/style.scss";

@import "../components/preloader/style.scss";
@import "../components/brief/style.scss";
@import "../components/image-upload/style.scss";
// @import "../components/color-pick/style.scss";
@import "../components/drag-and-drop/style.scss";
@import "../components/login-form/style.scss";
@import "../components/management/style.scss";
@import "../components/image-query/style.scss";
@import "../components/email-form/style.scss";
@import "../components/gallery/style.scss";
@import "../components/modals/style.scss";
@import "../components/image-display/style.scss";
@import "../components/stakeholder-questionnaire/style.scss";
@import "../components/custom-reference/style.scss";
@import "../components/avatar/style.scss";

@import "../components/layout/style.scss";

// .body--development {
@import "./new-style.scss";
