.checkbox-block {
	&:hover,
	&:focus {
		.checkbox-block__bullet:before {
			background-color: rgb(113 96 188 / .6);
		}
	}
}

textarea {
	font-family: 'FuturaNew-400', sans-serif;
	@include rem(font-size, 16px);
	@include rem(padding, 10px);
	border: 1px solid $color-gainsboro;
	border-radius: 5px;

	&::placeholder {
		color: #797979;
	}

	&:focus {
		border: 1px solid $color-silver;
		box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.08);
		outline: none;
	}

	&:hover {
		border: 1px solid $color-silver;
		box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.08);
	}
}

.inset-shadow {
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.08);
}
