.custom-reference {
	margin-top: 40px;
	margin-bottom: 80px;

	.b-loader {
		position: relative;
		padding: 11px;
		border: 1px dashed #BDBDBD;
		box-sizing: border-box;
		border-radius: 5px;
		font-size: 14px;
		text-align: center;

		&__btn {
			color: #BA085C;
			text-decoration: underline;
			cursor: pointer;
		}

		&__input {
			display: none;
		}

		&.drag-over {
			border-color: #BA085C;
		}

		.spinner {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba($color: #ffffff, $alpha: 0.5);
			border-radius: 5px;
	
			&-border {
				width: 1.5rem;
				height: 1.5rem;
				border-color: #BA085C;
				border-right-color: transparent;
			}
		}
	}

	.b-gallery {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 40px;
	}

	.custom-gallery-interactive {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		& > * {
			z-index: 1;
			opacity: 0;
			display: none;
			transition: opacity 1s;
		}

		&:hover {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(#000, 0.3);
			}

			& > * {
				opacity: 1;
				transition: opacity 1s;
				display: block;
			}
		}

		.blue-btn {
			@media (max-width: 1400px) {
				@include rem(font-size, 14px);
			}
		}
	}

	.js-custom-remove {
		@extend .b-gallery-final-block__img-del;
		position: static;
		transform: none;
		left: auto;
		top: auto;
		margin-bottom: 20px;
	}
}
