.image-upload {
	height: calc(100vh - 25px);

	&__filter-wrapper {
		display: flex;
	}

	&__filter {
		font-size: 15px;
		margin-right: 20px;
		text-transform: uppercase;
	}

	&__checkbox {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
		margin-top: 8px;

		input[type="checkbox"] {
			width: 1em;
			height: 1em;
			flex-shrink: 0;
		}

		&--sub {
			margin-left: 25px;
			margin-top: 0px;
			margin-bottom: 8px;
		}

		&--gray {
			color: #ff020d;
		}
	}

	&__tools-col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		max-height: 100%;
		height: calc(100vh - 25px);
	}

	&__preview {
		width: 100%;
		min-height: 350px;
		position: relative;
		background-color: #eeeeee;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__control {

		&-panel {
			height: 40px;
			width: 100%;
			bottom: 0;
			display: flex;
			flex-direction: row;

			&:last-child {
				border-right: 1px solid #666666;
			}
		}

		&-reset, &-all, &-send, &-delete {
			flex-grow: 1;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #333333;
			border-left: 1px solid #666666;
			border-top: 1px solid #666666;
			opacity: 0.7;
			background-color: #ffffff;

			&:hover {
				cursor: pointer;
			}

			line-height: 1;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		max-height: 100%;
		overflow-y: scroll;
		height: auto;

		&-item {
			position: relative;
			overflow: hidden;
			width: 140px;
			height: 140px;
			margin: 2.5px;

			&.updated-tags {
				box-sizing: border-box;
				border: 4px solid rgb(74, 203, 235);
			}

			&.has-no-tags {
				box-sizing: border-box;
				border: 4px solid red;

				&.updated-tags {
					&::before {
						content: "";
						position: absolute;
						width: 25%;
						height: 25%;
						background-color:  rgb(74, 203, 235);
						bottom: -15%;
						right: -15%;
						transform: rotate(45deg);
					}
				}
			}

			&.selected {
				&::after {
					content: '✓';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background-color: rgba(0, 0, 0, 0.3);
					color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 72px;
				}
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__drop {
		border: 1px dashed #ccc;
		min-height: 140px;
		height: calc(100vh - 50px);
		max-height: 100%;
		margin-bottom: 25px;

		&-form {
			margin-bottom: 10px;
		}

		#image-upload__form-file {
			display: none;
		}

		&.highlighted {
			border-color: purple;
		}

		.button {
			display: inline-block;
			padding: 10px;
			background: #ccc;
			cursor: pointer;
			border-radius: 5px;
			border: 1px solid #ccc;
			margin: 20px auto;

			&:hover {
				background: #ddd;
			}
		}
	}

	.message {
		padding: 20px;
		border: 1px #ff0000 dashed;
		color: #ff0000;
	}
}
