#managers-test-score--test-1 {
	margin-bottom: 60px;

	tr {
		&:nth-child(1) {
			background-color: #770000;
			color: #ffffff;
		}

		&:nth-child(2) {
			background-color: #789e67;
			color: #ffffff;
		}

		&:nth-child(3) {
			background-color: #bbeba4;
			color: #565656;
		}

		&:nth-child(4) {
			background-color: #e49292;
			color: #ffffff;
		}

		&:nth-child(5) {
			background-color: #916666;
			color: #ffffff;
		}

		td {
			padding: 10px;
		}
	}
}

#managers-test-score--test-2 {
	margin-bottom: 60px;

	tr {
		&:nth-child(1) {
			background-color: #eb1919;
			color: #ffffff;
		}

		&:nth-child(2) {
			background-color: #5349dd;
			color: #ffffff;
		}

		&:nth-child(3) {
			background-color: #87b870;
			color: #ffffff;
		}

		&:nth-child(4) {
			background-color: #eeb4b4;
			color: #565656;
		}

		td {
			padding: 10px;
		}
	}
}
