.db-table {
	margin: 20px;

	thead {
		width: 100%;
	}

	tr {
		width: 100%;
		border-bottom: 1px solid #ccc;
	}

	td, th {
		padding: 20px;
		vertical-align: middle;

		&.a_c {
			text-align: center;
		}
	}

	&#links, &#ics, &#applicant-tests {
		tr {
			cursor: pointer;

			th {
				cursor: default;
			}
		}
	}

	.red {
		color: #d82525;
	}

	.yellow {
		color: #ec721b;
	}

	.green {
		color: #51c143;
	}

	.gray {
		color: #999999;
	}

	&--smallerSpace {
		border-top: 1px solid $color-black;
		border-bottom: 1px solid $color-black;
		@include rem(margin, 20px 0);

		thead {
			tr:first-child th {
				@include rem(padding-top, 20px);
			}
		}

		tbody {
			tr:last-child td {
				@include rem(padding-bottom, 20px);
			}
		}

		tr {
			border: none;
		}

		td, th {
			padding: 0 20px 0 0;
			opacity: 0.5;
			@include font-size(18px);
			@include rem(line-height, 21px);
		}
	}
}

.db-link {
	@include rem(margin, 0 20px);

	.text {
		color: $color-black;
		@include font-size(20px);
		@include rem(line-height, 22px);
		@include rem(margin-right, 16px);
	}

	.link {
		font-weight: 300;
	}
}

.b-link-info {
	font-size: 18px;
	border: 1px dashed #BDBDBD;
	border-radius: 5px;
	padding: 18px 20px;

	figcaption {
		margin-bottom: 0.9rem;
		white-space: normal;

		border-bottom: 1px solid #000;
		position: relative;

		#copy {
			position: absolute;
			top: 0;
			right: 0;
			outline: none;
			border: none;

			width: 18px;
			height: 20px;
			padding: 0;

			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16' fill='none'%3E%3Cg clip-path='url(%23clip0_788_7163)'%3E%3Cpath d='M2.59975 12.3346C1.391 12.3346 0.414062 11.4004 0.414062 10.2446V2.48605C0.414062 1.33019 1.391 0.395996 2.59975 0.395996H7.98118C9.18994 0.395996 10.1669 1.33019 10.1669 2.48605' stroke='black' stroke-miterlimit='10'/%3E%3Cpath d='M11.4011 3.66553H6.01967C4.81255 3.66553 3.83398 4.60128 3.83398 5.75558V13.5141C3.83398 14.6684 4.81255 15.6042 6.01967 15.6042H11.4011C12.6082 15.6042 13.5868 14.6684 13.5868 13.5141V5.75558C13.5868 4.60128 12.6082 3.66553 11.4011 3.66553Z' stroke='black' stroke-miterlimit='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_788_7163'%3E%3Crect width='14' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			background-color: transparent;
		}
	}

	figure {
		margin-bottom: 0;
	}

	.col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-bottom: 0.9rem;
	}

	p {
		margin-bottom: 0;
	}

	.title {
		font-family: 'FuturaNew-500', sans-serif;
		@include font-size(24px);
		color: #000;
	}

	.subtitle {
		font-family: 'FuturaNew-500', sans-serif;
		@include font-size(18px);
		color: rgba(0, 0, 0, .5);
		line-height: 1;
	}

	.copyEffect {
		background-color: rgb(0 0 255 / 10%);
		transition: background-color .2s cubic-bezier(0.55, 0.06, 1, 0.32) .2s;
	}

	.management-form__btn--save {
		border: 1px solid #16BECF;
		background: #16BECF;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		color: #fff;
		font-size: 20px;
		font-weight: bold;

		&:hover {
			opacity: .9;
		}
	}

	.management-form__btn--act {
		border: 1px solid #F04B54;
		background: #F04B54;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		color: #fff;
		font-size: 20px;
		font-weight: bold;

		&:hover {
			opacity: .9;
		}
	}

	.btn-bs--danger {
		border: 1px solid #000;
		background: #000000;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		color: #fff;
		font-size: 20px;
		font-weight: bold;

		&:hover {
			opacity: .9;
		}
	}
}
