.btn {
	border: none;
	outline: none;
	box-shadow: none;
	color: #fff;
	cursor: pointer;
	@include rem(font-size, 20px);
	font-weight: 500;
	line-height: 1;
	text-align: center;
	padding: .4em 1.3em;
	border-radius: 5px;
}

.btn--load-more {
	background-color: $color-jam;
	z-index: 10;
	position: absolute;
	bottom: -60px;
	left: calc(50% - 95px);
	width: 190px;
}

.btn--continue, .btn--return {
	background-color: $color-jam;
	flex-shrink: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled {
		background-color: $color-gainsboro;
	}

	&:hover {
		background-color: $color-cerise;
	}
}

.btn--continue {
	&:after {
		content: '';
		display: block;

		@include rem(width, 24px);
		@include rem(height, 16px);

		margin-left: .5em;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z' fill='white'/%3E%3C/svg%3E%0A");
	}
}

.btn--return {
	@include rem(margin-right, 40px);

	&:before {
		content: '';
		display: block;

		@include rem(width, 24px);
		@include rem(height, 16px);

		margin-right: .5em;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;

		background-image: url("data:image/svg+xml,%3Csvg width='25' height='16' viewBox='0 0 25 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z' fill='white'/%3E%3C/svg%3E%0A");
		transform: rotate(180deg);
	}
}

.btn--s {
	background-color: #ccc;
	margin-right: 20px;
	flex-shrink: 0;
}

.blue--send {
	background-color: $color-jam;
	flex-shrink: 0;

	&:hover,
	&:focus {
		background-color: $color-cerise;
	}
}

.blue-btn {
	color: #fff;
	font-family: 'FuturaNew-500', sans-serif;
	font-weight: 500;
	@include rem(font-size, 20px);
	background-color: $color-blue;
	text-align: center;
	border-radius: 5px;
	cursor: pointer;
	line-height: 1.6;
	@include rem(padding, 0 20px);
	@include rem(min-width, 180px);

	&:hover,
	&:focus {
		background-color: #4FD4E1;
	}
}

.btn--disabled, .js-status-pending {
	background-color: $color-gainsboro !important;
	cursor: default !important;
}

// Для корректного отображения bootstrap-кнопок
.btn-bs {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #0d6efd;
	text-decoration: none;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid #0d6efd;
	padding: 0.375rem 0.75rem;
	border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	margin: 0.25rem 0.125rem;

	&:active,
	&:hover {
		color: #fff;
		background-color: #0d6efd;
		border-color: #0d6efd;
	}

	&:disabled,
	&.disabled {
		pointer-events: none;
		opacity: 0.4;
		cursor: default;
	}
}

.btn-bs--danger {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	text-decoration: none;
	vertical-align: middle;
	user-select: none;
	border: 1px solid #dc3545;
	padding: 0.375rem 0.75rem;
	border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	margin: 0.25rem 0.125rem;

	color: #fff;
	background-color: #dc3545;

	&:active,
	&:hover {
		background-color: #bb2d3b;
		border-color: #b02a37
	}
}

.btn-bs--warning {
	border: 1px solid #ffc107;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	color: #fff;
	background-color: #ffc107;

	&:active,
	&:hover {
		background-color: #ffca2c;
		border-color: #ffc720
	}
}

.btn-bs--back {
	@extend .btn-bs--danger;
	cursor: pointer;
	float: right;

	&:active,
	&:hover {
		color: #fff;
	}

	h1 & {
		font-size: .3em;
	}
}

.btn-bs--sm {
	padding: .25rem .5rem;
	font-size: .875rem;
	border-radius: .2rem;
}

.btn-bs--close {
	box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
		padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;

}
