.email-form {

	&--inner {
		@include rem(padding, 16px);
		background: #FFFFFF;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		@include rem(margin-bottom, 32px);

		.body--intro & {
			@include rem(margin-bottom, 14px);
		}
	}

	&__input {
		width: 100%;
		border: none;
		border-bottom: 1px solid $color-silver;
		@include rem(margin-bottom, 16px);
		font-family: 'FuturaNew-500', sans-serif;
		@include rem(font-size, 14px);
		@include rem(line-height, 18px);
		box-shadow: none;

		&::placeholder {
			@include rem(font-size, 14px);
			@include rem(line-height, 17px);
			color: $color-silver;
			font-family: 'FuturaNew-400', sans-serif;
		}

		&:focus {
			outline: none;
		}
	}

	&__lable-button {
		@include rem(font-size, 14px);
		@include rem(line-height, 17px);
		color: #404041;
	}

	&__add-button {
		width: 20px;
		height: 20px;
		background: $color-jam;
		border-radius: 5px;
		margin-right: 12px;
		padding: 0;
		border: none;
		color: white;
		cursor: pointer;

		&:active,
		&:hover,
		&:focus {
			background: $color-cerise;
			border: none;
			outline: none;
		}
	}
}
