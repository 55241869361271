$login-form--border-color: #cccccc;

.login-form {
	display: flex;
	flex-direction: column;
	max-width: 300px;
	margin: 20px auto;
	border: 1px solid $login-form--border-color;
	border-radius: 10px;
	overflow: hidden;

	input, button {
		padding: 10px;
		font-size: 16px;
		border: none;
	}

	input[type="text"], input[type="password"] {
		color: #666666;
		padding-left: 20px;
	}

	input[type="text"] {
		border-bottom: 1px solid $login-form--border-color;
	}

	button[type="submit"] {
		background-color: #0ad142;
		color: #ffffff;
		cursor: pointer;
	}
}
