// Подлключаем необходимые стили bootstrap

// 1. Сначала включите функции (чтобы Вы могли управлять цветами, SVG, вычислением и т.д.)
@import "~bootstrap/scss/functions";

// 2. Включите сюда любые переопределения переменных по умолчанию

// 3. Включите оставшуюся часть необходимых таблиц стилей Bootstrap
@import "~bootstrap/scss/variables";

// 4. Включите любой необязательный Bootstrap CSS по мере необходимости
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/spinners";

.drag-and-drop {
	position: relative;
	padding: 11px;
	border: 1px dashed #BDBDBD;
	box-sizing: border-box;
	border-radius: 5px;
	font-size: 14px;
	text-align: center;

	&__btn {
		color: #BA085C;
		text-decoration: underline;
		cursor: pointer;
	}

	&__input {
		display: none;
	}

	&.drag-over {
		border-color: #BA085C;
	}

	.spinner {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba($color: #ffffff, $alpha: 0.5);
		border-radius: 5px;

		&-border {
			width: 1.5rem;
			height: 1.5rem;
			border-color: #BA085C;
			border-right-color: transparent;
		}
	}
}
