:root {
	/** Заводим все используемые цвета, котоыре могут зависеть от темы.
	Два файла вариантов всегда используются отдельно друг от друга.
	Поэтому не требуется переопределение, но нужны одинаковые названия. */
	--theme-color: #ED1846;
	--theme-color-2: #7160BC;
	--highlight-text: #16BECF;
	--loader-linear: linear-gradient(
    #6C3CE8 0%,
    #BA085C 25%,
    #F04B54 50%,
    #FDB913 75%,
    #16BECF 100%
  );
	--input-checked-color: #7160BC;

	.highlight-theme {
		color: var(--theme-color);
	}

	.highlight-theme-2 {
		color: var(--highlight-text);
	}

	--theme-color-transp-06: rgba(237, 24, 70, 0.6);
	--theme-color-transp-09: rgba(237, 24, 70, 0.9);
	--theme-color-2-transp-06: rgba(113, 96, 188, 0.6);
	--highlight-text-transp-06: rgba(22, 190, 207, 0.6);
	--input-checked-transp-06: rgba(113, 96, 188, 0.6);
}
