.loader {
	&__outer {
		position: relative;
		@include rem(width, 360px);
		@include rem(height, 360px);

		background-image: linear-gradient(135deg, #FEED07 0%, #FE6A50 5%, #ED00AA 15%, #2FE3FE 50%, #8900FF 100%);
		border-radius: 50%;

		animation-duration: 2s;
		animation-name: rotate;
		animation-iteration-count: infinite;

		&:before {
			content: '';
			@include rem(width, 350px);
			@include rem(height, 350px);
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			border-radius: 50%;
		}
	}

	&__wrapper {
		@include rem(margin-bottom, 25px);
		position: relative;
	}

	&__text {
		@include rem(font-size, 16px);
		color: $color-grey;
		margin-bottom: 0;
		text-align: center;
	}

	&__title {
		@include rem(font-size, 38px);
		line-height: 1.2;
		font-weight: 700;
		color: #000;

		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

.loader--base {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: $preloader--z;
	background: #fff;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	.loader__title {
		margin: 0;
		text-align: center;

		h3 {
			@include rem(font-size, 22px);
		}

		h4 {
			@include rem(font-size, 20px);
			font-weight: 400;
		}
	}
}

.body--loader {
	overflow: hidden;

	.page__content {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-right: 0;
	}
}

@keyframes rotate {
    0% {transform:rotate(0deg);}
    100% {transform:rotate(360deg);}
}
