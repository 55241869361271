// grid, sizes, settings
$main-font--size: 20;

$max-site--width: 1920px;

$header--height: 90px;
$header--z: 110;

// $sidebar--width: 168px;
$sidebar--width: 8vw;
$sidebar--hover-width: 408px;
$sidebar--z: 11;

$preloader--z: 12;

$content-padding--left: 13vw;
$content-padding--right: 5vw;
$content-padding--right--middle: 20vw; // ~408px

// colors
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #404041;
$color-blue: #16BECF;
$color-marguerite: #7160BC;
$color-chetwode: #69729F;
$color-jam: #BA085C;
$color-cerise: #CB337B;
$color-gainsboro: #E0E0E0;
$color-ghost: #F2F4FF;
$color-silver: #BDBDBD;

// mixins
@mixin rem($property, $values) {
	$px: ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto or $value == '0px' {
			$px: append($px, $value);
			$rem: append($rem, $value);
		} @else {
			$val: $value / 1px;

			$px: append($px, $value);
			$rem: append($rem, ($val / $main-font--size + rem));
		}
	}

	@if $px == $rem {
		#{$property}: $px;
	} @else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}

// support classes
.input--hidden {
	width: 1px;
	height: 1px;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	outline: none;
	border: none;
	appearance: none;
	-webkit-appearance: none;
}
