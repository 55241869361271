.image-query {
	&__get-button {
		border: 1px solid black;
		padding: 20px;
		max-width: 200px;

		&:hover {
			cursor: pointer;
		}
	}

	&__preview {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		margin-top: 40px;
		max-height: 90vh;
		overflow-y: scroll;
	}

	&__checkbox {
		display: flex;
		align-items: center;

		&--sub {
			margin-left: 40px;
		}

		input[type="checkbox"] {
			width: 20px;
			height: 20px;
		}
	}

	&__image {
		position: relative;
		height: 140px;
		margin: 2.5px;

		img {
			height: 100%;
			object-fit: cover;
		}
	}
}
