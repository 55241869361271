@import 'croppie/croppie.css';

.avatar {
	max-width: 400px;
	margin: 0 auto;
	display: block;

	&__preview {
		width: 300px;
		margin: 0 auto;
		padding: 0 50px;

		img {
			max-width: 100%;
		}

		display: none;
	}

	.croppie-container.is-empty {
		.cr-boundary,
		.cr-slider-wrap {
			display: none;
		}

		.avatar__preview {
			display: block;
		}
	}

	.cr-boundary {

	}

	.cr-slider-wrap {

	}

	.input-file {
		position: absolute;
		visibility: hidden;
		opacity: 0;
	}

	.label-file {
		cursor: pointer;
	}

	.input-group {
		margin-top: 15px;
	}
}
