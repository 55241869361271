// --- old ---
// .management-form {
// 	width: 500px;
// 	max-width: 500px;
// 	font-size: 28px;
// 	flex-wrap: wrap;
// 	display: flex;
// 	margin: 20px;

// 	h5 {
// 		flex-basis: 200px;
// 		border-bottom: 1px solid #cccc;
// 		margin-bottom: 10px;
// 	}

// 	&__input {
// 		flex-basis: 250px;
// 		font-size: 16px;
// 		border: none;
// 		border-bottom: 1px solid #cccc;
// 		margin-bottom: 10px;
// 	}

// 	&__input-wrapper {
// 		flex-basis: 250px;
// 		border-bottom: 1px solid #cccc;
// 		margin-bottom: 10px;
// 	}

// 	&__btn {
// 		width: 200px;
// 		height: 32px;
// 		margin: auto;
// 		margin-top: 20px;
// 		margin-bottom: 20px;
// 		font-size: 18px;
// 		border: 1px solid #000000;
// 		cursor: pointer;
// 	}

// 	&__btn--save {
// 		background-color: #a5d0d4;
// 	}

// 	&__btn--act {
// 		background-color: #caa5d4;
// 	}

// 	&__btn--del {
// 		background-color: #ffffff;
// 		border-color: #de2323;
// 		color: #de2323;
// 	}

// 	&__btn:disabled {
// 		background-color: #e0e0e0;
// 		cursor: default;
// 	}

// 	.info-message {
// 		display: block;
// 		text-align: center;
// 		font-size: 14px;
// 		color: red;
// 		flex-basis: 100%;
// 	}
// }
// -- old --

.management-form {
	font-size: 18px;
	@include rem(line-height, 22px);

	.form-group {
		@include rem(margin-top, 10px);

		+ .btn-bs {
			@include rem(margin-top, 20px);
		}

		label {
			@include rem(margin-top, 10px);
			font-family: 'FuturaNew-500', sans-serif;
			color: $color-black;

			padding-top: 0;
			padding-bottom: 0;
			@include rem(line-height, 22px);
		}

		input {
			font-weight: 300;
			font-family: 'FuturaNew-300', sans-serif;
			@include rem(line-height, 22px);
			@include rem(padding-left, 10px);

			&[readonly],
			&[readonly="readonly"],
			&[type="text"]:read-only {
				&:focus,
				&:focus-within {
					outline: none;
					border: none;
					box-shadow: none;
				}
			}
		}

		&.bordered {
			position: relative;
			// border-bottom: 1px solid $color-black;
			@include rem(padding-bottom, 10px);
			// @include rem(margin-bottom, 16px);

			&::before {
				position: absolute;
				content: '';
				bottom: 0;
				left: 0.5rem;
				width: calc(100% - 1rem);
				height: 1px;
				background-color: $color-black;
			}
		}

	}

	.form-label--outside {
		@include rem(margin-top, 10px);
		font-family: 'FuturaNew-500', sans-serif;
		color: $color-black;

		padding-top: 0;
		padding-bottom: 0;
		@include rem(line-height, 22px);
	}

	&__btn--spinner {
		margin-left: 1rem;

		.spinner-border {
			margin-left: .5em;
			display: none;
		}

		&.in-progress {
			.spinner-border {
				margin-left: .5em;
				display: inline-block;
			}
		}
	}
}
