@import "./styles/form.scss";
@import "./styles/db-table.scss";
@import "./styles/brief.scss";
@import "./styles/tests.scss";

body.body--management {
	max-width: 95vw;

	.layout-wrapper {
		padding-right: 0;

		.header {
			display: none;
		}

		.page {
			margin-left: 0;
			max-width: 100%;

			&__content {
				padding-right: 0;
				padding-top: 5px;
				padding-bottom: 2rem;
			}
		}

		.sidebar {
			display: none;
		}
	}

	.inline-avatar {
		display: inline-block;
		width: 2em;
		height: 2em;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}

	.b-gallery-final-block__comment-btn {
		&:hover,
		&:focus {
			cursor: default;
			color: $color-blue;
			text-decoration: underline;
		}
	}


	.profile {
		&__btn {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 7px 15px;

			background-color: #fff;
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
			border-radius: 5px;

			&:hover {
				background-color: #F2F4FF;
			}

			margin-bottom: 8px;
			cursor: pointer;
		}

		&__btn-txt {
			font-size: 14px;
			line-height: 1.2;
		}

		&__btn-checkbox {
			width: 17px;
			height: 17px;

			flex-shrink: 0;
			visibility: hidden;
			background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cellipse cx='8.51269' cy='8.5' rx='8.51269' ry='8.5' fill='%237160BC'/%3E%3Cpath d='M5.41748 8.50031L8.31953 10.8185L12.3824 6.18213' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}

		&__input:checked + .profile__btn {
			.profile__btn-checkbox {
				visibility: visible;
			}

			background-color: #F2F4FF;
		}

		&__input:disabled + .profile__btn {
			opacity: 0.4;
			pointer-events: none;
		}

		&__input {
			width: 1px;
			height: 1px;
			overflow: hidden;
			position: absolute;
			z-index: -1;
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
			margin-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			padding-bottom: 0;
			outline: none;
			border: none;
			appearance: none;
			-webkit-appearance: none;
		}
	}

	[for="emailsToSend"] {
		font-family: 'FuturaNew-500', sans-serif;
		color: $color-black;

		padding-top: 0;
		padding-bottom: 0;
		line-height: 1.2;
	}
}

.management {
	&__avatar {
		width: 200px;
		margin: auto;
		margin-bottom: 20px;

		img {
			max-width: 100%;
		}
	}
}
