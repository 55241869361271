.stakeholder-questionnaire {
	&__list {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
	}

	&__row {
		flex-basis: 100%;
		border-bottom: 1px solid #ccc;
		padding: 10px;
	}

	.b-questionnaire {
		width: 100%;
		height: 80px;
		display: flex;
		flex-direction: row;
		cursor: pointer;

		&__title {
			flex-basis: 60%;
			font-size: 40px;
			line-height: 40px;

			height: 100%;
			display: flex;
			align-items: center;
		}

		&__info {
			flex-basis: 40%;
		}

		&__info-row {
			span {
				display: inline-block;
				width: 150px;
				font-weight: 700;
			}
		}
	}
}

.stakeholder-questionnaire-page {
	.cursor-pointer {
		cursor: pointer;
	}
}
