.brief {
	&__next {
		display: inline-block;
		padding: 10px;
		border: 1px solid #BA085C;
		width: auto;
		margin-top: 20px;
		margin-bottom: 20px;
		cursor: pointer;
		text-decoration: none;
		color: black;
	}

	&__comment {
		textarea {
			height: 6em;
			width: 100%;
		}

		&--up {
			span {
				color: $color-blue;
			}
		}

		&--down {
			span {
				color: $color-gainsboro;
			}
		}

		thin {
			font-weight: lighter;
		}
	}

	&__images {
		max-height: 50vh;
		overflow-y: scroll;
		display: flex;
		flex-wrap: wrap;
	}

	&__image {
		margin: 2px;
		max-height: 200px;
		position: relative;

		img {
			height: 100%;
		}

		&.selected {
			&::after {
				content: '✓';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, 0.3);
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 72px;
			}
		}
	}

	ul, ol {
		margin-bottom: 20px;

		li {
			margin-left: 20px;
		}

		ul, ol {
			margin-left: 20px;
		}
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style-type: decimal;
	}

	h2 {
		margin-top: 10px;
		margin-bottom: 10px;
		font-size: 1.5em;
	}

	[name="brandbook"]:disabled {
		background: transparent;
		border-bottom: 1px solid $color-jam;

		&::placeholder {
			color: $color-jam;
		}
	}

	.layout-wrapper .sidebar__content video {
		margin-left: 2.5rem;
	}

	&.body--intro .layout-wrapper .logo__text {
		display: block;
	}

	.modal--no-image__button__stay,
	.btn--load-more,
	.modal--finished__button__download {
		background-color: var(--theme-color);

		&:hover {
			background-color: var(--theme-color-transp-06); // ! error ?
		}
	}

	.btn--load-more {
		white-space: nowrap;
	}

	.layout-wrapper .sidebar__content {
		padding: 110px 0;
	}

	.progress #continue {
		max-width: 11%;

		&::before { // иногда видно белую границу между прогрессбаром и кнопкой
			content: '';
			position: absolute;

			left: -5px;
			top: 0;

			width: 5px;
			height: 100%;

			background-color: var(--theme-color);
		}
	}

	.progress #send {
		width: 100%;
		height: 100%;
		max-width: 100%;
		position: absolute;
	}

	.gallery-base-block img {
		width: 100%;
	}

	@media screen and (max-width: 1500px) {
		.layout-wrapper .sidebar:after {
			bottom: 80px;
		}
	}

	@media screen and (max-width: 1366px) {
		.b-modal-comment__area {
			height: 20%;
		}

		.layout-wrapper .sidebar__content {
			padding: 60px 0;
		}
	}

	@media screen and (max-width: 1024px) {
		.b-modal-comment__area {
			height: 10%;
		}
	}
}
