body {
	width: 100%;
	max-width: $max-site--width;
	margin: auto;
	background: #ffffff;
	overflow-x: hidden;
}

.layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;

	min-height: 100vh;
	// IE
	// height: 100%;
	padding-bottom: 60px;

	margin: 0 auto;
	width: 100%;
	padding-right: $sidebar--width;
	padding-left: 48px;

	.header {
		width: 100%;
		max-width: $max-site--width;
		padding: 48px 0 4px;
		background-color: #ffffff;
	}

	.logo {
		display: flex;
		// width: 100%;
		// max-width: $content-padding--left;
		// // justify-content: space-around;
		// justify-content: flex-start;
		// padding-left: 15px;
		// padding-right: 5px;
		// flex-wrap: wrap;
		// flex-shrink: 0;

		&__image {
			width: 48px;
			height: 51px;
			margin-right: 14px;
		}

		&__text {
			font-family: 'FuturaNew-500', sans-serif;
			align-self: center;
			@include rem(font-size, 16px);
			@include rem(line-height, 20px);
		}
	}

	.page {
		// display: flex;
		// flex-direction: row;
		flex-grow: 1;
		flex-shrink: 0;
		min-height: 1px;

		// padding: 0 80px 2em 248px;
		// padding-right: $content-padding--right;
		// padding-left: $content-padding--left;
		width: 1164px;
		max-width: 74vw;
		margin: 0 auto;
		// width: 100%;
		// max-width: calc(100% - #{$content-padding--left});
		// margin-left: auto;

		padding-top: 50px;

		&__content {
			position: relative;
			width: 100%;
			flex-shrink: 1;
			flex-grow: 1;
			// padding-right: $content-padding--right;
			// padding-top: $header--height + 20px;
			padding-top: 62px;
		}
	}

	.sidebar {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;

		z-index: $sidebar--z;

		width: $sidebar--width;
		height: 100vh;

		background: #ffffff;
		box-shadow: 0px 4px 8px rgba(174, 180, 210, 0.1);
		border-left: 1px solid $color-silver;
		transition: all 0.3s ease;
		padding: 35px 0 40px 0;

		&:focus,
		&.is-open,
		&:hover {
			// width: $sidebar--hover-width;
			.sidebar__content {
				transform: translateX(-100%);
			}
		}

		&__content {
			// width: 100%;
			// right: 0;
			width: $sidebar--hover-width;
			left: $sidebar--width;
			transition: transform 1s ease;
			box-shadow: -2px 0px 0px 2px rgba(0, 0, 0, 0.04), -8px 0px 6px rgba(0, 0, 0, 0.08);

			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			padding-top: $header--height + 20px;
			overflow: hidden;

			border-left: 1px solid $color-silver;
			background-color: #ffffff;
			display: flex;
			flex-direction: column;
			// justify-content: center;
			align-items: center;

			// @todo временная демонстрация
			p {
				@include rem(font-size, 24px);
				text-align: left;
				@include rem(margin, 0px 50px 70px);

				// &> span {
				// 	color: $color-jam;
				// }
			}

			img {
				display: block;
				margin: auto;
			}

			video {
				// width: 90%;
				margin: 0 auto;
				padding: 20px;
				box-sizing: content-box;
				box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.18);
				border-radius: 6px;
			}

			svg {
				max-width: 100%;
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 60px; // 2rem
			right: 50%;
			transform: translateX(50%);
			@include rem(width, 64px);
			@include rem(height, 64px);
			border: 5px solid $color-ghost;
			background-color: $color-blue;

			background-image: url("data:image/svg+xml,%3Csvg width='16' height='23' viewBox='0 0 16 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.2066 16.0213C5.18096 15.8665 5.18096 15.7117 5.15533 15.557C5.12969 15.4022 5.15533 15.2216 5.15533 15.0669C5.15533 14.164 5.30913 13.3644 5.5911 12.7195C5.87307 12.0489 6.23195 11.4814 6.69336 10.9913C7.15476 10.5012 7.61617 10.0884 8.12885 9.72732C8.64153 9.36619 9.12857 9.00506 9.56434 8.64393C9.97448 8.2828 10.3334 7.92167 10.6153 7.53475C10.8973 7.14783 11.0511 6.68352 11.0511 6.16762C11.0511 5.44536 10.7435 4.87788 10.1539 4.43936C9.56434 3.97505 8.64152 3.7687 7.38547 3.7687C6.74462 3.7687 6.15505 3.87188 5.64237 4.05244C5.10406 4.233 4.61702 4.46516 4.15561 4.72311C3.6942 4.98106 3.30969 5.239 2.97645 5.52275C2.61758 5.80649 2.30998 6.03865 2.05364 6.21921L0.105469 3.61393C0.592511 3.07223 1.15645 2.60793 1.77167 2.19521C2.38688 1.78249 3.05336 1.44716 3.74547 1.16341C4.43758 0.905462 5.15533 0.699103 5.89871 0.570129C6.66772 0.441154 7.38547 0.36377 8.10321 0.36377C10.3334 0.36377 12.0765 0.828078 13.3069 1.78249C14.5629 2.71111 15.1781 3.97505 15.1781 5.57434C15.1781 6.63193 15.0243 7.50896 14.6911 8.17962C14.3579 8.85029 13.9734 9.46937 13.4863 9.93368C12.9993 10.4238 12.461 10.8623 11.897 11.2234C11.3331 11.5845 10.8204 11.9973 10.3334 12.41C9.84631 12.8485 9.43617 13.3386 9.12857 13.8803C8.79533 14.4478 8.61589 15.17 8.61589 16.0213H5.2066ZM4.59138 20.4322C4.59138 19.7099 4.82209 19.1425 5.25786 18.7039C5.71927 18.2912 6.30885 18.0591 7.05223 18.0591C7.82124 18.0591 8.43645 18.2654 8.89786 18.7039C9.35927 19.1167 9.58998 19.7099 9.58998 20.4322C9.58998 21.1545 9.35927 21.7219 8.9235 22.1605C8.46209 22.599 7.84688 22.8053 7.05223 22.8053C6.30885 22.8053 5.71927 22.599 5.25786 22.1605C4.82209 21.7219 4.59138 21.1545 4.59138 20.4322Z' fill='white'/%3E%3C/svg%3E%0A");
			background-repeat: no-repeat;
			background-position: center center;
			border-radius: 50%;
			background-size: 30%
		}
	}

	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: $header--z;
		height: 40px;
		padding: 0;
		margin: 0;

		box-shadow: 0px -10px 20px 20px rgb(255 255 255);
	}

	@media screen and (max-width: 960px) {
		body:not(.body--tutor) & {
			.page {
				max-width: 100%;

				&__content {
					padding-left: 1rem;
					padding-right: 1rem
				}
			}
		}
	}

	@media screen and (min-height: 800px) {
		.page__content {
			padding-top: $header--height + 60px;
		}
	}
}

.body--medium .layout-wrapper {
	padding-right: $content-padding--right--middle;
}

.body--wide .layout-wrapper .page__content {
	padding-right: .5rem;
}

.body--intro .layout-wrapper {
	padding-right: 0;

	.sidebar {
		display: none;
	}

	.logo__text {
		display: none;
	}

	.greeting {
		flex: 0 0 35vw;
		max-width: 700px;
		padding-top: $header--height + 20px;
		// display: flex;
		// align-items: center;
		padding-left: 2rem;

		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: left center;
	}

	.appeal {
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
	}

	.page__content {
		padding-right: 13vw;
	}

	@media screen and (max-width: 1400px) {
		.appeal {
			@include rem(font-size, 16px);
		}

		.page__content {
			padding-right: 5vw;
		}
	}

	@media screen and (max-width: 1024px) {
		.appeal {
			@include rem(font-size, 14px);
		}
	}

	@media screen and (max-height: 800px) {
		.greeting {
			align-items: flex-start;
		}
	}

	@media screen and (max-width: 960px) {
		.page {
			flex-wrap: wrap-reverse;
		}

		.greeting {
			max-width: 100%;
			flex-basis: 100%;
			padding-left: .5rem;
			padding-right: .5rem;
			padding-bottom: 0.5rem;
		}

		.appeal {
			position: relative;
			padding-top: 6em;
		}

		.appeal-text {
			max-width: 60%;
		}
	}
}

.body--tutor .layout-wrapper {
	padding-right: 0;

	.page {
		min-width: 100%;

		&__content {
			display: flex;
			align-items: center;
			padding-right: 0;
		}

	}
}

// Версия для печати
$print-page--width: 1754px;
// $print-page--height: 1240px;
$print-page--height: 1140px;
// $print-page--top-indent: 20px;

$print-fix-header--height: 100px;

.body--print.brief {
	width: $print-page--width;

	.layout-wrapper {
		padding: 0;

		.header,
		.sidebar,
		.js-add-btn {
			display: none;
		}

		.page {
			max-width: 100%;
			/** Сетка
				в 3 колонки
				w 500
				gap 10
			 */
			width: 1530px;

			&__content {
				padding: 0rem 4rem;
			}
		}
	}

	/** Range form */
	// .range-form-comment {
	// 	margin-bottom: 1rem;
	// }
	// .range-form-comment__area {
	// 	padding: 0;
	// }

	.range-form-comment--brandbook .range-form-comment__area {
		height: 6rem;
	}


	/** Final-Gallery на печать */
	// TODO: учет шапок\подписей, перенос их внутрь или stamp в Masonry
	.gallery-final__header {
		display: none;
		height: $print-fix-header--height;

		h2 {
			font-size: 3rem;
			line-height: $print-fix-header--height;
			margin: 0 !important;
		}

		&.js-header-use {
			display: block;
			position: absolute;
		}
	}

	.gallery-final__header.js-header-use + [data-target-group] {
		// padding-top: $print-fix-header--height;
		margin-top: 0;
	}

	.print-wrapper {
		// height: $print-page--height;
		height: $print-page--height + $print-fix-header--height;
	}

	.gallery-final__box {
		// min-height: $print-page--height + $print-fix-header--height;
		// margin-top: 0;
		min-height: $print-page--height;
		margin-top: $print-fix-header--height;
	}

	/**
	[data-target-group] .gallery-final__box:first-child {
		min-height: $print-page--height;
		margin-top: $print-fix-header--height;
	}
	*/

	// checkboxes
	.form-checkbox__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		padding: 4% 4% 0;
		margin-bottom: 0;
	}

	// .form-checkbox__item:not(:last-child) {
	// 	margin-left: 22px;
	// }

	.form-checkbox__label {
		margin-left: 1rem;
	}
	//

	.gallery-final--print {
		overflow: hidden;
	}

	/** Проблема размеров
		берем максимум высоты в 1100рх
		имея запас от максимальной в 1140рх
		рассчитаем самый длинный коммент
		и самый большой набор чекбоксов

		рассчитаем и зададим ограничение для:
		b-gallery-final-block__image
		с учетом блоков (113px)
		и текста (140рх)

		TODО:
		Попробовать сделать на лету,
		до инита и реинита массонри.
		Тогда можно учесть наличие или
		отсутсвие комментов и\или чебоксов
	 */
	.b-gallery-final-block {
		// height: 45vh; // ~514px
		// overflow: hidden;
		margin-bottom: 0;
		align-items: flex-start;

		&__img {
			/** Проявляется на вертикальных длинных изображениях
			Для них допустим незначительный "кроп"
			В противном случае заменить на:
			object-fit: contain;
			*/
			object-fit: cover;
			height: 100%;
		}

		&__image {
			// max-height: 1000px; (!)

			// 	height: 60%;
			// 	flex: 1 1 60%;

			// 	img {
			// 		height: 100%;
			// 		object-fit: contain;
			// 	}
			&:after {
				content: none;
			}
		}

		&__comment-btn,
		&__comment-del {
			display: none;
		}

	}

	/** Grid */

	.col-print-1 {
		flex-basis: 8.33333333%;
		max-width: 8.33333333%;
	}

	.col-print-2 {
		flex-basis: 16.66666667%;
		max-width: 16.66666667%;
	}

	.col-print-3 {
		flex-basis: 25%;
		max-width: 25%;
	}

	.col-print-4 {
		flex-basis: 33.33333333%;
		max-width: 33.33333333%;
	}

	.col-print-5 {
		flex-basis: 41.66666667%;
		max-width: 41.66666667%;
	}

	.col-print-6 {
		flex-basis: 50%;
		max-width: 50%;
	}

	.col-print-7 {
		flex-basis: 58.33333333%;
		max-width: 58.33333333%;
	}

	.col-print-8 {
		flex-basis: 66.66666667%;
		max-width: 66.66666667%;
	}

	.col-print-9 {
		flex-basis: 75%;
		max-width: 75%;
	}

	.col-print-10 {
		flex-basis: 83.33333333%;
		max-width: 83.33333333%;
	}

	.col-print-11 {
		flex-basis: 91.66666667%;
		max-width: 91.66666667%;
	}

	.col-print-12 {
		flex-basis: 100%;
		max-width: 100%;
	}

	.col-print-offset-1 {
		margin-left: 8.33333333%;
	}
}

.body--final {
	.title-main {
		font-weight: bold;
		margin-bottom: 100px;
	}

	.tone {
		margin-bottom: 74px;
	}

	.feedback {
		margin-bottom: 98px;
	}

	.b-comment {
		margin-bottom: 16px;
	}
}

/** Классы документа, если потребуются */
.fanda-theme {

}

.zebra-theme {

}

.desktop body {
	@media (max-width: 1024px) {
		min-width: 1024px;
		overflow-x: auto;
	}
}

.mobile body {

}
